<template>
  <div class="p-4">
    <p><strong>หลักคิดเกี่ยวกับ &ldquo;ศักยภาพด้านพลังงาน&rdquo;</strong></p>
    <ul>
      <li>
        <strong>ศักยภาพพลังงานทดแทนที่คงเหลืออยู่จริง</strong>ในจังหวัด ได้แก่
        แหล่งวัสดุพลังงาน(เช่น ชีวมวล)ที่ยังไม่มีการนำไปใช้ประโยชน์อื่น
        และมีโอกาสที่จะนำมาผลิตพลังงานได้จริง
      </li>
      <li>
        ข้อมูลเชิงปริมาณเกี่ยวกับศักยภาพด้านพลังงาน เป็นข้อมูลที่ได้จากการ
        &ldquo;ประมาณการ&rdquo; จึงอาจมีความคลาดเคลื่อนสูงมาก
      </li>
    </ul>
    <p><strong>แนวเขียน &ldquo;ศักยภาพด้านพลังงาน&rdquo;</strong></p>
    <ul>
      <li>
        อ้างอิงข้อมูลเชิงปริมาณเกี่ยวกับแหล่งพลังงาน (เช่น พืชเพาะปลูก,
        พื้นที่หลังคา ฯลฯ) จากแหล่งข้อมูลที่น่าเชื่อถือ
        และควรเป็นข้อมูลที่ตรงประเด็น ไม่ล้าสมัยเกินไป
        ควรเป็นข้อมูลเชิงสถิติครอบคลุมช่วงเวลา 4-5 ปีที่ผ่านมา
      </li>
      <li>
        อาจแสดงข้อมูลเชิงสถิติประกอบข้อเขียน
        แต่ควรพยายามงดเว้นสถิติหรือรายละเอียดปลีกย่อยที่ไม่จำเป็น
      </li>
      <li>
        ข้อเขียนเกี่ยวกับ<strong
          >ศักยภาพพลังงานที่เหลืออยู่จริงในจังหวัด</strong
        >
        ต้องระบุชนิดของพลังงาน/วัตถุดิบและพื้นที่ศักยภาพให้ชัดเจน
        และอธิบายเหตุผลที่เชื่อว่ามีโอกาสนำมาใช้เพื่อผลิตพลังงานได้จริง
        (อาจอ้างอิงความเห็นของผู้นำในวงการที่เกี่ยวข้อง)
      </li>
      <li>
        ควรระบุผลคำนวณเปรียบเทียบว่าปริมาณการผลิตพลังงานจากศักยภาพดังกล่าว
        คิดเป็น<strong>สัดส่วนเท่าใดของปริมาณการใช้พลังงานขั้นสุดท้าย</strong>ของจังหวัด
      </li>
      <li>
        ควรระบุประเด็นท้าทาย (challenges) ในการนำศักยภาพแต่ละด้านมาใช้จริง เช่น
        ต้นทุนอันเนื่องจากความผันผวนของราคาตลาด, ข้อจำกัดในการคัดแยก-ขนส่ง ฯลฯ
      </li>
    </ul>
    <guiding-common-editor />
  </div>
</template>

<script>
import GuidingCommonEditor from '@/components/guiding/GuidingCommonEditor.vue';

export default {
  name: 'guiding-energy-capability',
  components: {
    GuidingCommonEditor,
  },
};
</script>
