<template>
  <div class="p-4">
    <p>
      <strong>หลักคิดเกี่ยวกับ &ldquo;การใช้พลังงานขั้นสุดท้าย&rdquo;</strong>
    </p>
    <ul>
      <li>
        ข้อมูลการใช้พลังงานขั้นสุดท้าย แสดงถึงปริมาณพลังงานที่ใช้
        และสัดส่วนชนิดของพลังงาน/เชื้อเพลิงที่ใช้
      </li>
      <li>
        ข้อมูลการใช้พลังงานฯ เพียงปีเดียว ไม่สามารถสื่อประเด็นได้ชัดเจน
        จำเป็นต้องแสดงให้เห็นทิศทางการเปลี่ยนแปลงในช่วง 4-5 ปีที่ผ่านมา
      </li>
      <li>
        ทิศทางการเปลี่ยนแปลงและหรือข้อมูลที่ดูผิดปกติ
        ย่อมมีเหตุที่มาซึ่งน่าจะวิเคราะห์/ประเมินได้จากสถานการณ์ทั่วไปของจังหวัดและสถานการณ์พลังงาน
      </li>
    </ul>
    <p><strong>แนวเขียน &ldquo;การใช้พลังงานขั้นสุดท้าย&rdquo;</strong></p>
    <ul>
      <li>อ้างอิงข้อมูลจากแหล่งที่น่าเชื่อถือ</li>
      <li>
        <strong>ข้อมูลเชิงสถิติ</strong>ควรนำเสนอในรูป<strong>กราฟ</strong>
        โดยเลือกใช้ชนิดของกราฟ (เช่น กราฟเส้น, กราฟแท่ง, กราฟวงกลม ฯลฯ)
        ที่เหมาะสมกับลักษณะขอบข่ายของข้อมูลแต่ละเรื่อง
        ซึ่งจะช่วยให้ผู้อ่านสังเกตเห็นนัยของข้อมูลได้ง่ายกว่าการอ่านตารางตัวเลขหรือข้อความบรรยาย
        <ul>
          <li>กราฟเส้น เหมาะสำหรับแสดงทิศทางการเปลี่ยนแปลงเทียบกับเวลา</li>
          <li>
            กราฟแท่ง
            เหมาะสำหรับเปรียบเทียบปริมาณของพลังงาน/เชื้อเพลิงต่างชนิดกัน(ในปีเดียวกัน)
          </li>
          <li>
            กราฟวงกลม เหมาะสำหรับแสดงสัดส่วนของพลังงาน/เชื้อเพลิงชนิดต่าง ๆ
            เทียบกับปริมาณการใช้ทั้งหมด(ในปีเดียว)
          </li>
        </ul>
      </li>
      <li>พยายามงดเว้นการบรรจุสถิติหรือรายละเอียดปลีกย่อยที่ไม่จำเป็น</li>
      <li>
        ตั้งข้อสังเกตเกี่ยวกับข้อมูลที่ดูผิดปกติ(ถ้ามี)
        และวิเคราะห์สาเหตุที่น่าจะเป็น
      </li>
      <li>
        ระบุถึงทิศทางและอัตราการเปลี่ยนแปลง และวิเคราะห์สาเหตุที่น่าจะเป็น
      </li>
      <li>
        ในการวิเคราะห์ &ldquo;สาเหตุ&rdquo;
        ควรพยายามบ่งชี้ปัจจัยที่มีผลกระทบในระดับลึกลงไป (เช่น ประเภทธุรกิจ)
        โดยอาจอ้างอิงข้อมูลเชิงคุณภาพจากแหล่งที่น่าเชื่อถือ (เช่น
        ความเห็นของผู้นำเศรษฐกิจในพื้นที่)
      </li>
    </ul>
    <guiding-common-editor />
  </div>
</template>

<script>
import GuidingCommonEditor from '@/components/guiding/GuidingCommonEditor.vue';

export default {
  name: 'guiding-energy-usage',
  components: {
    GuidingCommonEditor,
  },
};
</script>
