<template>
  <div class="p-4">
    <p>
      <strong
        >หลักคิดเกี่ยวกับ &ldquo;โครงสร้างพื้นฐานด้านพลังงาน&rdquo;</strong
      >
    </p>
    <ul>
      <li>
        ข้อมูลโครงสร้างพื้นฐานด้านพลังงาน เป็นส่วนหนึ่งของข้อมูลที่สื่อถึง
        <strong>&ldquo;ดุลยภาพด้านพลังงาน&rdquo;</strong> ของจังหวัด
        คือเป็นข้อมูลด้านกำลังการผลิตที่จะนำไปเปรียบเทียบกับปริมาณการใช้พลังงาน
        แสดงถึงระดับ<strong>การพึ่งพาตนเองด้านพลังงาน</strong>ของจังหวัด
      </li>
      <li>โครงสร้างพื้นฐานฯ มีนัยเกี่ยวกับความมั่นคงด้านพลังงานของชาติ</li>
    </ul>
    <p><strong>แนวเขียน &ldquo;โครงสร้างพื้นฐานด้านพลังงาน&rdquo;</strong></p>
    <ul>
      <li>
        ข้อมูลโครงสร้างพื้นฐานฯ โดยเฉพาะในส่วนของประเภท, ความจุ, กำลังการผลิต
        ฯลฯ สามารถแสดงเป็นตารางข้อมูลได้
        และอาจมีบางส่วนที่เหมาะสมสำหรับแสดงด้วยกราฟ
        ส่วนข้อมูลที่ตั้งควรแสดงเป็นแผนที่
      </li>
      <li>
        ข้อเขียนเกี่ยวกับ<strong>โครงสร้างพื้นฐานฯที่มีอยู่แล้ว</strong>
        อาจระบุถึงสภาพทางกายภาพ, อัตราการใช้กำลังการผลิต,
        ศักยภาพในการปรับเปลี่ยนวัตถุประสงค์ใช้งาน ฯลฯ
      </li>
      <li>
        ควรระบุถึง<strong>แผนอนาคต</strong> ได้แก่
        ประเด็นในแผนระดับประเทศที่เกี่ยวข้องกับการพัฒนาโครงสร้างพื้นฐานในพื้นที่
        เช่น แผนปฏิรูปด้านพลังงาน, PDP ฯลฯ
      </li>
    </ul>
    <guiding-common-editor />
  </div>
</template>

<script>
import GuidingCommonEditor from '@/components/guiding/GuidingCommonEditor.vue';

export default {
  name: 'guiding-infrastructure',
  components: {
    GuidingCommonEditor,
  },
};
</script>
