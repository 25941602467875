<template>
  <div class="p-4">
    <p>
      <strong
        >หลักคิดเกี่ยวกับ &ldquo;แผนผังแสดงการไหลของข้อมูลพลังงาน&rdquo;</strong
      >
    </p>
    <p>
      เป็นแผนผังแสดงการไหลของข้อมูล
      โดยแสดงข้อมูลปริมาณการจัดหาพลังงานขั้นต้นทั้งหมด ปริมาณการแปรรูปพลังงาน
      ปริมาณการนำเข้า-ส่งออกพลังงานของพื้นที่
      รวมทั้งปริมาณการใช้พลังงานขั้นสุดท้าย
      ที่แสดงถึงความต้องการการใช้พลังงานจำแนกรายสาขาเศรษฐกิจ ได้แก่
      สาขาเกษตรกรรม สาขาเหมืองแร่ สาขาอุตสาหกรรมการผลิต สาขาการก่อสร้าง
      สาขาบ้านอยู่อาศัย สาขาธุรกิจการค้า และสาขาการขนส่ง
    </p>
    <p>
      <strong>
        แนวทางการจัดทำ &ldquo;แผนผังแสดงการไหลของข้อมูลพลังงาน&rdquo;
      </strong>
    </p>
    <ul>
      <li>แหล่งพลังงานสอดคล้องกับบริบทการดำเนินงานจริงของพื้นที่</li>
      <li>
        มีวิธีการเก็บรวบรวมข้อมูลการใช้พลังงานที่ถูกต้อง
        อ้างอิงจากแหล่งที่น่าเชื่อถือ มีเอกสารแสดงแหล่งที่มาของข้อมูล
      </li>
      <li>
        กรณีที่มีการตั้งสมมติฐานในการประเมินข้อมูล
        สมมติฐานนั้นควรเป็นที่ยอมรับจากหน่วยงานส่วนกลางของกระทรวงพลังงาน
        หรือผ่านความเห็นชอบจากผู้เชี่ยวชาญ
      </li>
    </ul>
    <guiding-common-editor />
  </div>
</template>

<script>
import GuidingCommonEditor from '@/components/guiding/GuidingCommonEditor.vue';

export default {
  name: 'guiding-sankey-diagram',
  components: {
    GuidingCommonEditor,
  },
};
</script>
