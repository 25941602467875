<template>
  <div class="p-4">
    <p><strong>หลักคิดเกี่ยวกับ &ldquo;ดัชนีวัดมิติต่าง ๆ&rdquo;</strong></p>
    <ul>
      <li>
        ให้ความสำคัญกับดัชนีที่เป็น<strong>ตัวชี้วัดสากลด้านพลังงาน</strong>
        โดยเฉพาะดัชนีที่มีการกำหนดค่าเป้าหมายภายใต้นโยบายพลังงานระดับชาติ
      </li>
      <li>
        ค่าดัชนีของจังหวัดเพียงอย่างเดียว หรือค่าดัชนีเพียงปีเดียว
        ไม่สามารถสื่อประเด็นได้ชัดเจน จำเป็นต้องมีการ<strong
          >เปรียบเทียบกับเกณฑ์อ้างอิง</strong
        >
        (benchmark) ที่เหมาะสม (เช่น ค่าเฉลี่ยของประเทศ,
        ค่าเฉลี่ยของจังหวัดที่มีโครงสร้างเศรษฐกิจคล้ายคลึงกัน,
        ค่าเป้าหมายตามนโยบายพลังงานระดับชาติ ฯลฯ)
        และจำเป็นต้องแสดงให้เห็น<strong>ทิศทางการเปลี่ยนแปลง</strong>ในช่วง 4-5
        ปีที่ผ่านมา
      </li>
      <li>
        <strong>การเบี่ยงเบนจากเกณฑ์อ้างอิง</strong>
        และ<strong>ทิศทางการเปลี่ยนแปลง</strong>ของค่าดัชนี
        ย่อมมีเหตุที่มาซึ่งน่าจะวิเคราะห์/ประเมินได้จากสถานการณ์ทั่วไปของจังหวัดและสถานการณ์พลังงาน
      </li>
    </ul>
    <p><strong>แนวเขียน &ldquo;ดัชนีวัดมิติต่าง ๆ&rdquo;</strong></p>
    <ul>
      <li>
        อ้างอิงข้อมูลจากแหล่งที่น่าเชื่อถือ
        อาจแสดงข้อมูลด้วยตารางหรือกราฟตามความเหมาะสม
      </li>
      <li>
        แสดงสถิติค่าดัชนีของจังหวัดในช่วง 4-5 ปีที่ผ่านมา
        ระบุถึงทิศทางและอัตราการเปลี่ยนแปลง
        ระบุความผิดปกติ(ถ้ามี)และอธิบายถึงสาเหตุที่น่าจะเป็น
      </li>
      <li>
        แสดงค่าดัชนีของจังหวัด เปรียบเทียบกับเกณฑ์อ้างอิงที่เหมาะสม
        ระบุถึงลักษณะและขนาดของความเบี่ยงเบนจากเกณฑ์(ถ้ามี)
        และอธิบายถึงสาเหตุที่น่าจะเป็น
      </li>
      <li>
        อธิบายว่าค่าดัชนีของจังหวัดในมิตินั้น ๆ
        สื่อให้เห็นความจำเป็นที่ควรเร่งปรับปรุงแก้ไขหรือพัฒนาในเรื่องใดหรือไม่อย่างไร
      </li>
    </ul>
    <guiding-common-editor />
  </div>
</template>

<script>
import GuidingCommonEditor from '@/components/guiding/GuidingCommonEditor.vue';

export default {
  name: 'guiding-metric',
  components: {
    GuidingCommonEditor,
  },
};
</script>
