<template>
  <div class="peno-current-situation">
    <vue-element-loading
      v-if="!ready"
      :active="!ready"
      spinner="spinner"
      color="#ED110E"
      class="position-relative mt-5"
      background-color="transparent"
    />
    <transition mode="out-in" name="fade">
      <div class="container-fluid" v-if="ready" id="main_content">
        <div class="row align-items-stretch">
          <div class="col-md-7">
            <h4
              class="
                font-weight-bold
                text-left
                d-flex
                mt-3
                align-items-baseline
              "
            >
              สถานการณ์ด้านพลังงานของจังหวัด{{ currentProvince.name_th }}
              <div
                class="text-gray text-medium pointer ml-3"
                v-tooltip="{
                  content:
                    'ข้อเขียนสถานการณ์ด้านพลังงานของจังหวัด รวมถึงแนวโน้มการเปลี่ยนแปลงจากอดีตถึงปัจจุบัน ประกอบด้วย การใช้พลังงานขั้นสุดท้าย, ศักยภาพด้านพลังงาน, โครงสร้างพื้นฐานด้านพลังงาน และดัชนีวัดมิติต่างๆ',
                }"
              >
                <u>ข้อมูลเพิ่มเติม</u>
              </div>
            </h4>
            <div class="d-flex align-items-center mt-3 pb-2 border-bottom menu">
              <div class="font-weight-bold mr-3">หัวข้อ</div>
              <select
                name="topic"
                class="custom-select"
                v-model="currentTab"
                @change="changeTab"
              >
                <option value="peno.current_situation.energy_diagram">
                  2.1 แผนภาพพลังงานของจังหวัด (Energy Balance)
                </option>
                <option value="peno.current_situation.energy_usage">
                  2.2 การใช้พลังงานขั้นสุดท้าย
                </option>
                <option value="peno.current_situation.energy_capability">
                  2.3 ศักยภาพพลังงาน
                </option>
                <option value="peno.current_situation.infrastructure">
                  2.4 โครงสร้างพื้นฐานด้านพลังงาน
                </option>
                <option value="peno.current_situation.metric">
                  2.5 ดัชนีชี้วัด
                </option>
              </select>
            </div>
            <transition mode="out-in" name="fade">
              <router-view class="animated" />
            </transition>
          </div>
          <div class="col-md-5 side-shadow overflow-hidden">
            <affix
              class="px-4 mt-3"
              relative-element-selector="#main_content"
              :offset="{ top: 0, bottom: 0 }"
              :scrollAffix="false"
            >
              <div class="d-flex flex-row justify-content-between mb-3">
                <div class="pointer text-blue" @click="showGuidingModal(0)">
                  <u>ดูคำแนะนำ</u>
                </div>
                <button
                  type="button"
                  @click="nextSituation()"
                  class="btn btn-outline-red"
                >
                  ไปต่อ
                  <eva-icon
                    name="arrow-ios-forward"
                    fill="currentColor"
                  ></eva-icon>
                </button>
              </div>
              <form
                action=""
                method="post"
                @submit.prevent="submitEnergyDiagram"
                v-promise-btn="{ action: 'submit' }"
                v-show="currentTab == 'peno.current_situation.energy_diagram'"
              >
                <div class="form-group">
                  <label
                  >โปรดสรุปสาระสำคัญเกี่ยวกับแผนภาพพลังงานของจังหวัด
                  </label>
                  <trumbowyg
                    ref="inputEnergyDiagram"
                    v-model="formEnergyDiagram.text"
                    :config="getConfig(5)"
                    class=""
                    id="inputEnergyDiagram"
                  ></trumbowyg>
                </div>
                <div class="text-right mb-4">
                  <button type="submit" class="btn btn-action mr-2" v-show="allowEdit">
                    บันทึก
                  </button>
                </div>
              </form>
              <form
                action=""
                method="post"
                @submit.prevent="submitEnergyUsage"
                v-promise-btn="{ action: 'submit' }"
                v-show="currentTab == 'peno.current_situation.energy_usage'"
              >
                <div class="form-group">
                  <label
                  >โปรดสรุปสาระสำคัญเกี่ยวกับการใช้พลังงานขั้นสุดท้ายของจังหวัด</label
                  >
                  <trumbowyg
                    ref="inputEnergyUsage"
                    v-model="formEnergyUsage.text"
                    :config="getConfig(1)"
                    class=""
                    id="inputEnergyUsage"
                  ></trumbowyg>
                </div>
                <div class="text-right mb-4">
                  <button type="submit" class="btn btn-action mr-2" v-show="allowEdit">
                    บันทึก
                  </button>
                </div>
              </form>
              <form
                action=""
                method="post"
                @submit.prevent="submitEnergyCapability"
                v-promise-btn="{ action: 'submit' }"
                v-show="
                  currentTab == 'peno.current_situation.energy_capability'
                "
              >
                <div class="form-group">
                  <label
                  >โปรดสรุปสาระสำคัญเกี่ยวกับศักยภาพด้านพลังงานของจังหวัด</label
                  >
                  <trumbowyg
                    ref="inputEnergyCapability"
                    v-model="formEnergyCapability.text"
                    :config="getConfig(2)"
                    class=""
                    id="inputEnergyCapability"
                  ></trumbowyg>
                </div>
                <div class="text-right mb-4">
                  <button type="submit" class="btn btn-action mr-2" v-show="allowEdit">
                    บันทึก
                  </button>
                </div>
              </form>
              <form
                action=""
                method="post"
                @submit.prevent="submitInfrastructure"
                v-promise-btn="{ action: 'submit' }"
                v-show="currentTab == 'peno.current_situation.infrastructure'"
              >
                <div class="form-group">
                  <label
                  >โปรดสรุปสาระสำคัญเกี่ยวกับโครงสร้างพื้นฐานด้านพลังงานในจังหวัด</label
                  >
                  <trumbowyg
                    ref="inputInfrastructure"
                    v-model="formInfrastructure.text"
                    :config="getConfig(3)"
                    class=""
                    id="inputInfrastructure"
                  ></trumbowyg>
                </div>
                <div class="text-right mb-4">
                  <button type="submit" class="btn btn-action mr-2" v-show="allowEdit">
                    บันทึก
                  </button>
                </div>
              </form>
              <form
                action=""
                method="post"
                @submit.prevent="submitMetric"
                v-promise-btn="{ action: 'submit' }"
                v-show="currentTab == 'peno.current_situation.metric'"
              >
                <div class="form-group">
                  <label
                  >โปรดสรุปสาระสำคัญของดัชนีวัดมิติต่าง ๆ
                    ด้านพลังงานของจังหวัด</label
                  >
                  <trumbowyg
                    ref="inputMetric"
                    v-model="formMetric.text"
                    :config="getConfig(4)"
                    class=""
                    id="inputMetric"
                  ></trumbowyg>
                </div>
                <div class="text-right mb-4">
                  <button type="submit" class="btn btn-action mr-2" v-show="allowEdit">
                    บันทึก
                  </button>
                </div>
              </form>
            </affix>
          </div>
        </div>
      </div>
    </transition>
    <modals-container></modals-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import Form from '../../../modules/form/form';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingEnergyUsage from '@/components/guiding/GuidingEnergyUsage.vue';
import GuidingEnergyCapability from '@/components/guiding/GuidingEnergyCapability.vue';
import GuidingInfrastructure from '@/components/guiding/GuidingInfrastructure.vue';
import GuidingMetric from '@/components/guiding/GuidingMetric.vue';
import GuidingSankeyDiagram from '@/components/guiding/GuidingSankeyDiagram.vue';
import {head, indexOf, last} from 'lodash';

export default {
  name: 'current-situation',
  data() {
    return {
      ready: false,
      currentTab: this.$route.name,
      situationRouteNameList: [
        'peno.current_situation.energy_diagram',
        'peno.current_situation.energy_usage',
        'peno.current_situation.energy_capability',
        'peno.current_situation.infrastructure',
        'peno.current_situation.metric',
      ],
      formEnergyDiagram: new Form({
        data: () => {
          return {
            text: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {},
      }),
      formEnergyUsage: new Form({
        data: () => {
          return {
            text: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {},
      }),
      formEnergyCapability: new Form({
        data: () => {
          return {
            text: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {},
      }),
      formInfrastructure: new Form({
        data: () => {
          return {
            text: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {},
      }),
      formMetric: new Form({
        data: () => {
          return {
            text: '',
            config: {
              resetAfterSuccess: false,
            },
          };
        },
        validations: {},
      }),
    };
  },
  created() {
    if (this.$route.name == 'peno.current_situation') {
      this.$router.push({name: head(this.situationRouteNameList)});
    }
  },
  mounted() {
    setTimeout(() => {
      this.onReady();

      window.onbeforeunload = () => {
        console.log('before leave');
        if (this.beforeLeave()) {
          return 'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!';
        } else {
          return void 0;
        }
      };
    }, 500);
  },
  beforeDestroy() {
    window.onbeforeunload = null;
  },
  watch: {
    $route(to, from) {
      this.currentTab = to.name;
      if (to.name == 'peno.current_situation') {
        this.$router.push({name: head(this.situationRouteNameList)});
      }
    },
  },
  computed: {
    ...mapState('peno', ['currentReport', 'allowEdit']),
    ...mapState(['meta']),
    ...mapGetters('peno', ['energySituations', 'currentProvince']),
    textEnergyDiagram() {
      const energyDiagram = this.energySituations.find((situation) => {
        return situation.type == 5;
      });
      if (energyDiagram) {
        return energyDiagram.content;
      }
      return '';
    },
    textEnergyUsage() {
      const energyUsage = this.energySituations.find((situation) => {
        return situation.type == 1;
      });
      if (energyUsage) {
        return energyUsage.content;
      }
      return '';
    },
    textEnergyCapability() {
      const energyCapability = this.energySituations.find((situation) => {
        return situation.type == 2;
      });
      if (energyCapability) {
        return energyCapability.content;
      }
      return '';
    },
    textInfrastructure() {
      const infrastructure = this.energySituations.find((situation) => {
        return situation.type == 3;
      });
      if (infrastructure) {
        return infrastructure.content;
      }
      return '';
    },
    textMetric() {
      const metric = this.energySituations.find((situation) => {
        return situation.type == 4;
      });
      if (metric) {
        return metric.content;
      }
      return '';
    },
    lastSituationRouteName() {
      return last(this.situationRouteNameList);
    },
  },
  methods: {
    ...mapMutations('peno', ['updateEnergySituation']),
    getConfig(type) {
      const url =
        '/api/media/report/' +
        this.currentReport.id +
        '/energy-situations/' +
        type;
      return {
        semantic: false,
        tagsToRemove: ['script', 'link'],
        autogrow: true,
        autogrowOnEnter: true,
        btns: [
          ['fontsize'],
          ['fontfamily'],
          ['underline', 'italic', 'bold'],
          ['table'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['image'],
          ['fullscreen'],
        ],
        btnsDef: {
          image: {
            dropdown: ['insertImage', 'upload'],
            ico: 'insertImage',
          },
        },
        plugins: {
          table: {
            styler: 'table table-bordered',
          },
          fontfamily: {
            fontList: this.$fontfamilyList,
          },
          upload: {
            headers: {'X-CSRF-TOKEN': this.meta.csrfToken},
            serverPath: url,
            fileFieldName: 'image',
            urlPropertyName: 'url',
            statusPropertyName: 'success',
          },
        },
      };
    },
    onReady() {
      this.formEnergyDiagram.text = this.textEnergyDiagram;
      this.formEnergyUsage.text = this.textEnergyUsage;
      this.formEnergyCapability.text = this.textEnergyCapability;
      this.formInfrastructure.text = this.textInfrastructure;
      this.formMetric.text = this.textMetric;

      this.formEnergyDiagram.cleanDirty();
      this.formEnergyUsage.cleanDirty();
      this.formEnergyCapability.cleanDirty();
      this.formInfrastructure.cleanDirty();
      this.formMetric.cleanDirty();

      this.ready = true;
    },
    changeTab(event) {
      const routeName = event.target.value;
      this.$router.push({name: routeName});
    },
    showGuidingModal() {
      var comp = (function (route) {
        switch (route) {
          case 'peno.current_situation.energy_diagram':
            return GuidingSankeyDiagram;
          case 'peno.current_situation.energy_usage':
            return GuidingEnergyUsage;
          case 'peno.current_situation.energy_capability':
            return GuidingEnergyCapability;
          case 'peno.current_situation.infrastructure':
            return GuidingInfrastructure;
          case 'peno.current_situation.metric':
            return GuidingMetric;
          default:
            return GuidingEnergyUsage;
        }
      })(this.currentTab);
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: comp,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    nextSituation() {
      const index = indexOf(this.situationRouteNameList, this.currentTab);
      if (index >= 0 && index + 1 != this.situationRouteNameList.length) {
        this.$router.push({
          name: this.situationRouteNameList[index + 1],
        });
      } else if (index + 1 == this.situationRouteNameList.length) {
        this.$router.push({
          name: 'peno.scenarios',
        });
      }
    },
    submitEnergyDiagram() {
      return this.formEnergyDiagram
        .post('/api/energy-situations/5')
        .then((situation) => {
          this.updateEnergySituation({type: 5, updatedSituation: situation});
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    submitEnergyUsage() {
      return this.formEnergyUsage
        .post('/api/energy-situations/1')
        .then((situation) => {
          this.updateEnergySituation({type: 1, updatedSituation: situation});
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitEnergyCapability() {
      return this.formEnergyCapability
        .post('/api/energy-situations/2')
        .then((situation) => {
          this.updateEnergySituation({type: 2, updatedSituation: situation});
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    submitInfrastructure() {
      return this.formInfrastructure
        .post('/api/energy-situations/3')
        .then((situation) => {
          this.updateEnergySituation({type: 3, updatedSituation: situation});
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    submitMetric() {
      return this.formMetric
        .post('/api/energy-situations/4')
        .then((situation) => {
          this.updateEnergySituation({type: 4, updatedSituation: situation});
        })
        .catch((error) => {
          console.error(error);
          this.$toasted.global.display_error({
            message: error,
          });
        });
    },
    beforeLeave() {
      let isChange = false;

      if (this.currentTab === 'peno.current_situation.metric') {
        isChange = this.formMetric.isDirty();
      }

      if (this.currentTab === 'peno.current_situation.energy_usage') {
        isChange = this.formEnergyUsage.isDirty();
      }

      if (this.currentTab === 'peno.current_situation.energy_capability') {
        isChange = this.formEnergyCapability.isDirty();
      }

      if (this.currentTab === 'peno.current_situation.energy_diagram') {
        isChange = this.formEnergyDiagram.isDirty();
      }

      if (this.currentTab === 'peno.current_situation.infrastructure') {
        isChange = this.formInfrastructure.isDirty();
      }

      return isChange;
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (this.beforeLeave()) {
      const answer = window.confirm(
        'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!'
      );
      if (answer) {
        next();
      }
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.beforeLeave()) {
      const answer = window.confirm(
        'แน่ใจหรือที่จะออกจากหน้านี้? มีข้อความที่ยังไม่ได้บันทึก!'
      );
      if (answer) {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.peno-current-situation {
  min-height: 100%;
  height: 1px;

  .menu {
    overflow-x: auto;
    overflow-y: hidden;

    .item.active {
      color: $red-color !important;
      border-bottom: 3px solid $red-color;
    }
  }
}

.affix {
  width: inherit;
  max-width: inherit;
  padding-right: 2.7rem !important;
}
</style>
